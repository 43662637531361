import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/components/datadog/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.1.3_react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/PPFormula-CondensedBlack.woff2\",\"weight\":\"900\",\"variable\":\"--font-ppformula\"}],\"variableName\":\"heading\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-barlow\"}],\"variableName\":\"barlow\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/accordion/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/blocks/faq/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/blocks/form-block/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/blocks/pipeline-locator/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/blocks/quote/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/dropdown-menu/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/emergency-banner/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/footer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/form-field/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/form/combo-box/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/form/dropdown/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/form/file-input/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/form/text-input/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/link/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/nav-bar/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/promo-input/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/radio-group/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles.css.ts")